import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import React from "react";
import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import API from '../api/api'
import Router from '../api/router'
import config from '../config'


const UserForm = (params) => {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    const [settings, setSettings] = useState({
	title: "",
	tab1:  "",
	active: true,
	tabs: [],
    })

    console.log("LOCATION", settings.coordinates.latitude, settings.coordinates.longitude)

    const [investments, setInvestments]   = useState([])


    useEffect(() => {
        console.log("Portfolio - useEffect")
        console.log("PARAMS", params)
    	settings.title        = params.title
	settings.active       = params.active
	settings.tab1         = params.tab1
	settings.errorMessage = params.errorMessage


    }, {});



    const renderTab = (active, title, content) => {
	return (
	    <>
       	      <div className={"mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full"+(active ? " bg-green-300 ": " bg-gray-300 ")}>
	  	<LockClosedIcon className={"h-8 w-8 "+(active ? " text-green-700 " : " text-gray-500 ")} aria-hidden="true" />
	      </div>
	      <div className="mt-3 text-center">
                <div className="text-3xl font-medium text-gray-900">
                  {title}
                </div>
	      </div>
	    </>
	)
	//{content()}
    }

    

    const renderErrorMessage = () => {
	if (!settings.errorMessage) {
	    return (
		    <></>
	    )
	}
	return (
		<div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">{settings.errorMessage}</div>
	)
    }
    console.log("LOGIN")
    
    return (
      <>
	<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">

	  <div className="min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
            <div className="px-4 py-4 md:px-12 md:py-12">
	      {renderTab(settings.title, settings.active, settings.tab1)}
              {renderErrorMessage()}
	    </div>
	    <a href={config.landingPageUrl} className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer">
	      Back home
	    </a>
	  </div>
        </div>
      </>
    )
}


export default UserForm;
