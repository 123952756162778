
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import API from '../api/api';
import Router from '../api/router';
import config from "../config";

import UserForm from "../Components/UserForm";
import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Logo from '../media/images/logo.png'

const Login = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const router   = new Router(navigate, location)

    const [credentials, setCredentials]   = useState({username: "", password: ""})
    const [errorMessage, setErrorMessage] = useState("")

    const is_login = location.pathname === config.route.login
	const onChange = (e) => {
        e.preventDefault()
        setCredentials(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setErrorMessage("")
    }

    const onError = (err) => {
        setErrorMessage(err)
    }

    const onLogin = async (e) => {
	API.login(router, credentials, onError)
    }

    const renderLogin = () => {
	return (
	  <>
		<p className="text-lg text-gray-500">
		  Login to your account
	        </p>
		<div className="flex flex-col flex-1">
		  <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Username</label>
		  <input value={credentials.username} name="username" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
		</div>
		<div className="flex flex-col flex-1">
		  <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
	    	  <input value={credentials.password} name="password" onChange={(e)=>onChange(e)} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="*******" />
		</div>
		<div className="flex flex-col">
		  <button type="submit" onClick={() => onLogin()} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
		    Log in
	          </button>
		  <a href={'./reset'} className="mt-4 text-gray-400 text-sm">Forgot your password?</a>
		</div>
	  </>
	)
  }

    return (
	  <>
	    <Helmet>
	      <title>Login</title>
	    </Helmet>
	    <>
	    <UserForm
	title={"Login"}
	active={true}
	tab1={renderLogin}
	errorMessage={errorMessage}
	    />
	    </>
	  </>
    )

}



export default Login
