
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import API from '../api/api'
import Router from '../api/router'
import config from "../config"

import UserForm from "../Components/UserForm"
import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Logo from '../media/images/logo.png'

const SuccessRegistration = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const router   = new Router(navigate, location)

    useEffect(() => {
    })
    
    const [credentials, setCredentials]   = useState({username: "", password: ""})
    const [errorMessage, setErrorMessage] = useState("")


    const renderSuccessRegistration = () => {
	return (
	  <>
	        <p className="text-lg text-gray-500">
		  Registration Successful! Please check your email...
	        </p>
	  </>
	)
  }

	return (
		<>
			<Helmet>
				<title>Registration Success</title>
			</Helmet>
		<UserForm
	    title="Registration Successful"
	    active={true}
	    tab1={renderSuccessRegistration}
	    errorMessage={errorMessage}
	    />
		</>)

}



export default SuccessRegistration
