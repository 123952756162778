
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import API from '../api/api'
import Router from '../api/router'
import config from "../config"

import UserForm from "../Components/UserForm"
import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Logo from '../media/images/logo.png'

const SuccessLogin = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const router   = new Router(navigate, location)


    useEffect(() => {
        setTimeout(() => {
            navigate(config.route.portfolio, {replace: true})
        }, 5000)
    })
    
    const [credentials, setCredentials]   = useState({username: "", password: ""})
    const [errorMessage, setErrorMessage] = useState("")


    const renderSuccessLogin = () => {
	const active = location === config.route.login
	return (
	  <>
	        <p className="text-lg text-gray-500">
		  Login Successful. You will be redirected...
	        </p>
	  </>
	)
  }

	return (
		<>
			<Helmet>
				<title>Login</title>
		</Helmet>
		<UserForm
	    title="Login"
	    active={true}
	    tab1={renderSuccessLogin}
	    errorMessage={errorMessage}
		/>
		</>)

}



export default SuccessLogin
