
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import API from '../api/api'
import Router from '../api/router'
import config from "../config"
import UserForm from "../Components/UserForm"

import { Route, Routes } from "react-router-dom";

import Logo from '../media/images/logo.png'

const Verify = () => {
    const navigate = useNavigate()
	const location = useLocation();
	const router   = new Router(navigate, location)

    const [errorMessage, setErrorMessage] = useState("")
    const [credentials, setCredentials]   = useState({username: "", password: ""})
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token')

    const onChange = (e) => {
        e.preventDefault()
        setCredentials(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setErrorMessage("")
    }

    const onError = (err) => {
        setErrorMessage(err)
    }

    const onVerify = async (e) => {
	console.log("ONVERIFY")
	API.execute(router, config.api.user.verify, credentials, onError)
    }

    const renderVerify = () => {
	return (
	  <>
	    <p className="text-lg text-gray-500">
	      Please check your email to verify your account.
	    </p>
	    <div className="flex flex-col flex-1">
	      <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Username</label>
	      <input value={credentials.username} name="username" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
	    </div>
	    <div className="flex flex-col flex-1">
		<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
	    	<input value={credentials.password} name="password" onChange={(e)=>onChange(e)} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="*******" />
	    </div>
	    <div className="flex flex-col">
		<button type="submit" onClick={() => onVerify()} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
		  Verify
	        </button>
		<a href={'./reset'} className="mt-4 text-gray-400 text-sm">Forgot your password?</a>
	    </div>
	  </>
	)
    }

    console.log("Login - RENDER")
    return (
	<UserForm
	    title="Verification"
	    active={true}
	tab1={renderVerify}
	errorMessage={errorMessage}
	/>
    )
}



export default Verify
