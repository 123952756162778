import { ThemeProvider } from 'styled-components'

import React from 'react';

//import React, { Component } from 'react';
//import { Provider, Observer  } from 'mobx-react-lite'

import 'bootstrap/dist/css/bootstrap.min.css';

import colors from 'tailwindcss/colors'
import {
  BrowserRouter as BrowserRouter,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";

import Header from './Header'
import Footer from './Footer'

import Overview from './Overview'
import Portfolio from './Portfolio'
import Dashboard from './Dashboard'
import PNL from './PNL'
import CashFlow from './CashFlow'
import Covenants from './Covenants'
import Login from './Login/Login'
import Reset from './Login/Reset'
import Registration from './Login/Registration'
import Verify from './Login/Verify'
import SuccessLogin from './Login/SuccessLogin'
import SuccessRegistration from './Login/SuccessRegistration'
import SuccessVerify from './Login/SuccessVerify'
import Landing from './Login/Landing'
import API from './api/api'
//import Profile from './Profile/'
import config from './config'
//import Dashboard from './Dashboard'
//import Front from './Front'

import './App.scss'


const App = () => {
    console.log("APP")
    console.log(API.loggedIn())
    return (
        <>
           <BrowserRouter>
                <Header/>
                {(API.loggedIn()) ?
                <>
                <Routes>
                    <Route path={config.route.overview}     element={<Overview/>}/>
                    <Route path={config.route.portfolio}    element={<Portfolio/>}/>
                    <Route path={config.route.dashboard}    element={<Dashboard/>}/>
                    <Route path={config.route.pnl}          element={<PNL/>}/>
                    <Route path={config.route.cashflow}     element={<CashFlow/>}/>
                    <Route path={config.route.covenants}    element={<Covenants/>}/>
                    <Route path={config.route.base}         element={<Portfolio/>}/>
                    <Route path={config.route.landing}      element={<Landing/>}/>
                    <Route path={config.route.loginsuccess} element={<SuccessLogin/>}/>
                    <Route path={config.route.successVerify} element={<SuccessVerify/>}/>
                    <Route path={config.route.pnl}          element={<PNL/>}/>
                </Routes>
                </> : <>
                <Routes>
                    <Route path={config.route.overview}    element={<Overview/>}/>
                    <Route path={config.route.portfolio}   element={<Portfolio/>}/>
                    <Route path={config.route.login}       element={<Login/>}/>
                    <Route path={config.route.registration}  element={<Registration/>}/>
                    <Route path={config.route.verify}      element={<Verify/>}/>
                    <Route path={config.route.reset}       element={<Reset/>}/>
                    <Route path={config.route.successlogin} element={<SuccessLogin/>}/>
                    <Route path={config.route.successVerify} element={<SuccessVerify/>}/>
                    <Route path={config.route.successRegistration} element={<SuccessRegistration/>}/>
                    <Route path={config.route.base}        element={<Landing/>}/>
                    <Route path={config.route.landing}     element={<Landing/>}/>
                </Routes>

                </>}
                <Footer/>
            </BrowserRouter>
        </>

    )

}

export default App
