
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import API from '../api/api'
import Router from '../api/router'
import config from "../config"

import UserForm from "../Components/UserForm"
import { CheckIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";

import Logo from '../media/images/logo.png'

const Registration = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const router   = new Router(navigate, location)

    const [credentials, setCredentials]   = useState({
	username: "",
	password: "",
	email:    "",
	displayname: "",
	firstname: "",
	lastname: "",
	jobdesc: "",
	organistion: "",
	country: "",
    })
    const [errorMessage, setErrorMessage] = useState("")

    const is_registeration = location.pathname === config.route.registration


    const onChange = (e) => {
        e.preventDefault()
        setCredentials(prevState => ({...prevState, [e.target.name]: e.target.value}))
        setErrorMessage("")
    }

    const onError = (err) => {
        setErrorMessage(err)
    }

    const onRegistration = async (e) => {
	console.log("ONLOGIN")
	credentials.email = credentials.username
	API.execute(router, config.api.user.create, credentials, onError)
    }

    const renderRegistration = () => {
	return (
	    <>
		<p className="text-lg text-gray-500">
		    Register your free demo account
	        </p>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email/Username</label>
		    <input value={credentials.username} name="username" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Display Name</label>
		    <input value={credentials.displayname} name="displayname" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="johnsmith" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">First Name</label>
		    <input value={credentials.firstname} name="firstname" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Last Name</label>
		    <input value={credentials.lastname} name="lastname" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="smith" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Job Description</label>
		    <input value={credentials.job_desc} name="jobdesc" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="e.g. CIO" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Organisation</label>
		    <input value={credentials.organisation} name="organisation" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="A-DCM" />
		</div>
		<div className="flex flex-col flex-1">
	 	    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Country</label>
		    <input value={credentials.country} name="country" onChange={(e)=>onChange(e)} focus="true" type="input" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="The Netherlands" />
		</div>
		<div className="flex flex-col flex-1">
		    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
	    	    <input value={credentials.password1} name="password" onChange={(e)=>onChange(e)} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="*******" />
		</div>
		<div className="flex flex-col flex-1">
		    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
	    	    <input value={credentials.password1} name="password" onChange={(e)=>onChange(e)} focus="true" type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="*******" />
		</div>
		<div className="flex flex-col flex-1">
		    <label className="text-gray-400 text-sm block mt-4 inline-block text-left">Accept GDPR</label>
	    	    <input value={credentials.accept_gdpr} name="accept_gdpr" onChange={(e)=>onChange(e)} focus="true" type="checkbox" className="rounded-md text-lg px-4 py-2  border border-gray-300 "/>
		</div>
		<div className="flex flex-col">
		    <button type="submit" onClick={() => onRegistration()} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
		       Register
	            </button>
		    <a href={config.route.login} className="mt-4 text-gray-400 text-sm">Register your account</a>
		</div>
	    </>
	)
    }

    console.log("Registration - RENDER")
	return (
	    <>
	    <Helmet>
		<title>Registration</title>
		</Helmet>
		<UserForm
	    title="Registration"
	    active={true}
	    tab1={renderRegistration}
	    errorMessage={errorMessage}
		/>
		</>
	)
}



export default Registration
