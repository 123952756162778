const config = {
    pageName: "A-DCM Risk Management",
    
    route: {
	//landing: "http://localhost:6110",
	landing: "https://adcm.sikkemasoftware.nl",
	base:          "/",
	landing:       "/landing",
	login:         "/user_login",
	registration:  "/user_registration",
	reset:         "/user_reset",
	verify:        "/user_verify",
	successlogin:  "/user_successlogin",
	successregisteration: "/user_succesregisteration",
	successverify: "/user_sucessverify",
	//resetSubmit: "/user_resetSubmit",
	profile:     "/user_profile",
	covenants:   "/covenants",
	cashflow:    "/cashflow",
	dashboard:   "/dashboard",
	pnl:         "/pnl",
	defaults:    "/defaults",
	overview:    "/overview",
	portfolio:   "/portfolio",
    },
    api: {
    	//url:       'http://127.0.0.1:6210/api/v01',
    	url:       'https://api.sikkemasoftware.nl:6210/api/v01',
	user: {
	    logout:       '/user/logout',
	    login:        '/user/login',
	    reset:        '/user/reset',
	    registration: '/user/registration',
	    verify:       '/user/verify',
	    get:          '/user/get',
	},
	investment: {
	    list:        '/investment/list',
	},
	risks: {
	    list:        '/risks/list',
	},
	pnl: {
	    list:    '/pnl/list',
	},
	cashflow: {
	    list:  '/cashflow/list',
	},
	covenant: {
	    list:  '/covenant/list',
	},
    	defaults: {
	    list:  '/defaults/list',
	},
    },
};
  

export default config;


